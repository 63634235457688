import React from "react";
import img from "../../assets/file.webp";
import "./About.css";

const Aboutus = () => {
  return (
    <>
      <section className="about" id="About Us">  
        <img src={img} alt="kk" />
    
        <div className="content">
          <h5>
            <h1 style={{color:"#F7911D"}}>Subu M Kota</h1>
            <h1>Founder | Philanthropist</h1>
            <h4>
              Subu Kota is a versatile entrepreneur with a successful,<br/>
            
              track record spanning <span class="word"></span>IT Biotech, Real Estate,<span class="word"></span>and E -<br/> 
            learning. His journey began in 1970 with a time - sharing<br/>
             
              application <span class="word"></span>venture, leading to<span class="word"></span> ventures like  <span class="word"></span>Shanta<br/> 
               
              Biotechnics, where<span class="word"></span> he pioneered <span class="word"></span> affordabl healthcare<br/> 

              
              solutions. Committed to impactful <span class="word"></span>philanthropy, Kota's<br/>  
              initiatives,such as Shanta Biotechnics,have significantly<br/>
              improved <span class="word"></span>healthcare access<span class="word"></span> in India. With a  "  can do  "<br/> 
              attitude, he continues<span class="word"></span> to make a <span class="word"></span>tangible<span class="word"></span> difference<br/> 
              
              through entrepreneurship and community service
            </h4>
          </h5>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
