import React from 'react';
import "./Footer.css";
import {  AiFillFacebook, AiFillLinkedin, AiFillInstagram } from "react-icons/ai";
 
 
import { Link } from 'react-router-dom';

const Footer = () => {
  const iconColor = "#FDFCFD";  

  return (
    <>
      

      <div className='footer'>
        

        <div className='mail'>
          <h1>Email</h1>
           
          <span> Email: hello@subukota.foundation</span>
        </div>

        <div className='social'>

          <h1>Social</h1>
          
          <a href="https://www.facebook.com/profile.php?id=61553603720070" target="_blank" rel="noopener noreferrer">
  <AiFillFacebook size={30} color={iconColor} />
</a>

           <Link to="Apply"><AiFillLinkedin size={30} color={iconColor} /></Link>
           <a href=" https://www.instagram.com/subukota.foundation/?next=%2F" target="_blank" rel="noopener noreferrer">
  < AiFillInstagram  size={30} color={iconColor} />
</a>

       
 

        </div>
      </div>  
    </>
  );
}

export default Footer;
