// Hero.js

import React from "react";
import video from "../../assets/Resize image project.png";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero" id="Home">
      <div className="hero-image-container">
        <img src={video} alt="" className="hero-image" />

        <div className="h-content">
          <h1>
            <span>S</span>
            <span style={{ color: "white" }}>upporting</span> <span>P</span>
            <span style={{ color: "white" }}>otential,</span> <span>A</span>
            <span style={{ color: "white" }}>ccelerating Innovation,</span>
            <br />
            <span style={{ color: "white" }}> and </span>
            <span>R</span>
            <span style={{ color: "white" }}>ecognizing</span> <span>K</span>
            <span style={{ color: "white" }}>nowledge through the</span>
            <br />
            <span style={{ color: "white" }}>Entrepreneurship</span>
          </h1>
        </div>

        {/* Apply Button for Mobile View */}
      </div>

      {/* <div className="register">
        <a href="https://docs.google.com/forms/u/3/d/e/1FAIpQLSdj7dzcJhA0rifTNP1I7tmN34O95yI4Lm1peC3042Mne7rOYw/viewform">
          <button>Apply</button>
        </a>
      </div> */}
    </div>
  );
};

export default Hero;
