import React from "react";
 import card from "../../assets/card-1.jpg"
 import card2 from "../../assets/card-2.jpg"
 import card3 from "../../assets/woman-hand-holding-plant-growing-from-coins-bottle.jpg"
 import './Spark.css'

const Spark = () => {  
  return (
    <>
      <section className="section"  id="Spark-heading">
        <div className="spark-heading"> <h1>SPARK</h1></div>

        <div className="spark-sub-heading">
          <h1>"SPARK, a brilliant collaboration between CrossBorders Initial Labs and Subu Kota Foundation, is the inspiring brainchild<br/>
               born from the fusion of innovation and social impact. Together, we ignite the flames of entrepreneurship, illuminating a<br/>
            path for aspiring startups to thrive and transform their ideas into impactful ventures."</h1>
        </div>

        <div className="spark">  
          <div className="card-1">
            <img src={card2} width={350} alt="" />

            <h1>Awareness</h1>  

<h5>
  Ignite student entrepreneurs
  <br />
  with workshops, seminars,
  <br />
  and guest lectures
</h5>
          </div>



          <div className="card-1">
            <img src={card3} width={350} alt="" />

            <h1> Grants</h1>

            <h5>
            Funding innovative business<br/>
             ideas and supporting early-stage<br/>
              start-ups with seed funding.
            </h5>

          
          </div>




          <div className="card-1">
            <img src={card}  width={370} alt="" />

            <h1> Investments</h1>

            <h5>
            Invest in start-ups for rapid<br/>
             growth with diligence,<br/>
              mentorship, and strategic
               guidance.
            </h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default Spark;
