import React from 'react';
import img1 from "../../assets/111.png";
import img2 from "../../assets/emphasis_6351298.png";
import img3 from "../../assets/333.png";
import img4 from "../../assets//444.png";
import img5 from "../../assets/letter_9454529.png";
import img6 from "../../assets/analysis_3793149.png";
import img7 from "../../assets/light-bulb_8230759.png";
import img8 from "../../assets/888.png";
import back from "../../assets/SKF site.png"

import './Howitwork.css';

const Howitwork = () => {
  return (
    <div className='howitwork-main' style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <h1>HOW IT WORKS?</h1>

      <div className='icons-set'>

        <div className='k1'>  
        <div className='icon-imgs1'>
            <img src={img1} alt='' width={100} />
            <h2>Submit your<br />  application</h2>
          </div>

          <div className='icon-imgs5'>
            <img src={img5} alt='' width={100} />
            <h2> Receive<br/> grants</h2>
          </div>
        </div>


     
        <div className='k2'>
        <div className='icon-imgs1'>
            <img src={img2} alt='' width={100} />
            <h2> Present your problem<br /> statement</h2>
          </div>

          <div className='icon-imgs5'>
            <img src={img6} alt='' width={100} />
            <h2> Build<br/> prototype</h2>
          </div>
        </div>




        <div className='k3'>
        <div className='icon-imgs1'>
            <img src={img3} alt='' width={100} />
            <h2> Receive<br/> confirmation</h2>
          </div>

          <div className='icon-imgs5'>
            <img src={img7} alt='' width={100} />
            <h2>  Pitch your<br/> solution</h2>
          </div>
        </div>




        <div className='k4'>
        <div className='icon-imgs1'>
            <img src={img4} alt='' width={100} />
            <h2> Attend <br/>workshops</h2> 
          </div>

          <div className='icon-imgs5'>
            <img src={img8} alt='' width={100} />
            <h2> Get your<br/> investment</h2>
          </div>
        </div>
       


      </div>
    </div>
  );
};

export default Howitwork;
