import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';

function App() {
  return (
    <Router>
      <div>
      
        <head>
          <link rel="icon" href="logo.jpg" />
        </head>

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
