import React, { useState } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";
import "./Navbar.css";
import logo from "../assets/b-logo2.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const toggle = () => {
    setClick(!click);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <nav>
      <div className="logo">
      <Link smooth to="/Home">
  <div className="subu">
    <div className="subu-logo">
      <img src={logo} width={60} alt="" />
    </div>
  </div>
</Link>

      </div>

      <div className={click ? "menu action" : "menu"}>
        <Link smooth to="#About Us" onClick={() => setClick(false)}>
          <h1>About Us</h1>
        </Link>
        <div
          className="dropdown"
          onMouseEnter={handleDropdown}
          onMouseLeave={handleDropdown}
        >
          <Link smooth to="#Programs" onClick={() => setClick(false)}>
            <h1>Programs</h1>
          </Link>
          {dropdown && (
            <div className="dropdown-content">
              <Link smooth to="#Spark-heading" onClick={() => setClick(false)}>
                <h1>Spark</h1>
              </Link>
              {/* Add more dropdown items as needed */}
            </div>
          )}
        </div>  
        <div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdj7dzcJhA0rifTNP1I7tmN34O95yI4Lm1peC3042Mne7rOYw/viewform">
            <button className="register-button">Apply</button>
          </a>
        </div>
      </div>

      <div className="hamburger" onClick={toggle}>
        {click ? (
          <FaTimes style={{ color: "black" }} size={25} />
        ) : (
          <FaBars style={{ color: "black" }} size={25} />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
