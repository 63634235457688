 // Home.js

import React, { useEffect } from 'react';
import Navbar from "../../component/Navbar";
import Hero from './Hero';
import Aboutus from './Aboutus';
import Spark from "../../pages/home/Spark";
import Howitwork from "../../pages/home/Howitwork";
import Core from "../../pages/home/Core";
import Apply from "../../pages/home/Apply";
import Footer from "../../component/Footer";
 

const Home = () => {
  useEffect(() => {
    document.title = 'Subukota foundation';
  }, []);

  return (
    <div className='mainhome'>
      

      <Navbar />
      <Hero />
      <Aboutus />
      <Spark />
      <Howitwork />
      <Core />
      <Apply />
      <Footer />
    </div>
  );
};

export default Home;
