import React from "react";
import raju from "../../assets//d1.png";
import raju2 from "../../assets/d-2.png";
import raju3 from "../../assets/d-3.png";
import raju4 from "../../assets/d4.png";
import "./Core.css";
  
const Core = () => {
  return (
    <div className="Core" >
      <div className="core-heading">
        <h1 style={{color:"#F7911D"}}>COMMITTEE</h1>
      </div>
      <div className="sub-heading">
        <h1>
          The <span style={{color:"#F7911D"}}>SPARK</span> - JNTUK
        </h1>
      </div>
      <div className="core-main">
        <div className="core-card-1">
          <div className="core-card-imgc">
            <img src={raju} alt="" />
          </div>

          <div className="core-card-content">
            <h2 style={{fontSize:"25px"}}>Shri Subu Kota </h2>
            <h3 style={{fontSize:"12px"}}>President - SK Foundation<br/>
            Founder - Boston Group </h3>
          </div>
        </div>

        <div className="core-card-2">
          <div className="core-card-imgc">
            <img src={raju2} alt="" />
          </div>

          <div className="core-card-content">
            <h2 style={{fontSize:"25px"}}> Subba Raju Pericherla</h2>
            <h3 style={{fontSize:"12px"}}> Committee Member<br/>
Founder - CrossBorders</h3>
          </div>
        </div>

        <div className="core-card-3">
          <div className="core-card-imgc">
            <img src={raju3} alt="" />
          </div>

          <div className="core-card-content">
            <h2  style={{fontSize:"25px"}}>Prof. Krishna Prasad</h2>
            <h3 style={{fontSize:"12px"}}> Committee Member<br/>
Principal - JNTUK</h3>
          </div>
        </div>

        <div className="core-card-4">
          <div className="core-card-imgc">
            <img src={raju4} alt="" />
          </div>

          <div className="core-card-content">
            <h2  style={{fontSize:"25px"}}>Prof. JVR Murthy</h2>
            <h3 style={{fontSize:"12px"}}> Committee Member<br/>
Director - IC , JNTUK</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Core;
