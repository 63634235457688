 // Apply.js

import React from 'react';
import apply from '../../assets/application-form-employment-document-concept.jpg';
import './Apply.css';

const Apply = () => {
  return (
    <div className='Apply' id='Apply'>
      <img className='apply-img' src={apply} alt='Application Background' />

      <div className='buttons'>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdj7dzcJhA0rifTNP1I7tmN34O95yI4Lm1peC3042Mne7rOYw/viewform">
          <button className="apply-button">Apply</button>
        </a>
      </div>
    </div>
  );
};

export default Apply;
